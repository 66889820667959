.entity-media-editor-header {
    gap: 20px;
    align-items: center;
    padding-top: 5px;
}

.entity-media-list-display {
    gap: 40px;
    align-items: center;
    width: 100%;
    padding-right: 10px;
}

.entity-media-details-form {
    width: 60%;
}

.entity-media-dialog {
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    padding-left: 4px !important;
    padding-right: 4px !important;
}

.entity-media-editor {
    gap: 10px;
}

.entity-media-list {
    overflow-y: auto;
    height: calc(80vh - 80px);
}

.add-video-editor {
    width: 600px;
}