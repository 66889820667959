.assignment-picklist-table-container {
    width: calc((90vw - 60px) / 2);
    min-height: calc(80vh - 100px);
}

.assignment-picklist-container {
    width: 100%;
    height: 100%;
    min-width: 80vw;
    min-height: 80vh;
}