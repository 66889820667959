.quality-metric-kind-dashboard {
    gap: 10px;
    height: 100%;
}

.quality-metric-kind-content {
    gap: 10px;
    height: calc(100vh - 230px);
    overflow-y: auto;
}

.quality-metric-kind-list {
    gap: 10px;
    width: 50%;
    max-width: 600px;
}

.quality-metric-kind-editor-no-selection {
    width: 50%;
    height: 200px;
    align-items: center;
    justify-content: center;
}

.quality-metric-kind-editor {
    gap: 10px;
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 50%;
    max-width: 600px;
    padding: 15px;
}

.quality-metric-kind-editor-title {
    font-size: larger;
    font-weight: bold;
    border-bottom: 1px solid lightgray;
}

.quality-metric-kind-editor-values-container {
    margin-top: 20px;
    gap: 10px;
}

.quality-metric-kind-field-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    
}

.quality-metric-kind-field-input {
    width: 80%;
}

.quality-metric-kind-field-label {
    display: inline-block;
    width: 100px;
}

.quality-metric-value-table-button {
    width: 24px;
    height: 24px;
}
