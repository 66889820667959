.logo-upload-save {
    width: 113px;
}

.logo-title {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.logo-container {
    height: 120px;
    width : 120px;
}


.logo-image {
    height    : 120px;
    width     : 120px;
    object-fit: contain;
}

.banner-image {
    height    : 120px;
    width     : 720px;
    object-fit: contain;
}