/* large devices */
.initial-setup-dialog-large-device {
    height: 60vh;
}

.initial-setup-content-large-device {
    height: calc(60vh - var(--setup-workflow-steps-height) - var(--setup-workflow-commandbar-height));
}

.initial-setup-terms-large-device {
    height: calc(60vh - var(--setup-workflow-steps-height) - var(--setup-workflow-commandbar-height) - var(--setup-workflow-commandbar-height));
}

/* small devices */

.initial-setup-dialog-small-device {
    height: 100vh;
}

.initial-setup-content-small-device {
    height: calc(100vh - var(--setup-workflow-steps-height) - (var(--setup-workflow-commandbar-height) * 2));
}

.initial-setup-terms-small-device {
    height: calc(100vh - var(--setup-workflow-steps-height) - (var(--setup-workflow-commandbar-height) * 3));
}
/* common */

.initial-setup-step.p-steps {
    height: var(--setup-workflow-steps-height);
    width: 90vw;
    max-width: 800px;
}

.initial-setup-controlbar {
    height: var(--setup-workflow-commandbar-height);
}