/* Sign up Card Large Device */

.signup-card {
    margin        : 10px;
    width         : 20vw;
    max-width     : 450px;
    transition    : height .65s;
    transition    : width .65s;
    padding-bottom: 10px;
}

.signup-card-header {
    height          : 180px;
    width           : 100%;
    background-color: var(--node-marketplace-green);
    transition      : height .65s;
}

.signup-card-header-selected {
    height          : 200px;
    width           : 100%;
    background-color: var(--node-marketplace-green);
    transition      : height .65s;
}

.signup-card-title {
    font-size  : 1.5rem;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.signup-card-action {
    height    : 100%;
    width     : 100%;
    transition: height .65s;
    transition: width .65s;
}

.signup-card-selected {
    margin    : 10px;
    width     : 20vw;
    max-width : 450px;
    height    : 280px;
    transition: height .65s;
    transition: width .65s;
}


.signup-card-action-button-large {
    width: 120px;
}

/* Sign up Card Small Device */

.signup-card-small {
    height    : 60px;
    margin    : 5px 10px;
    width     : calc(100vw - 20px);
    transition: height .65s;
    transition: width .65s;
}

.signup-card-header-small {
    height          : 100%;
    width           : 40vw;
    background-color: var(--node-marketplace-green);
    transition      : height .65s;
    transition      : width .65s;
}

.signup-card-title-small {
    display: none;
}

.signup-card-action-small {
    height    : 100%;
    width     : 60vw;
    transition: height .65s;
    transition: width .65s;
}

.signup-card-small-selected {
    margin    : 5px 5px;
    width     : calc(100vw - 10px);
    transition: width .65s;
    transition: width .65s;
}

.signup-card-header-small-selected {
    height          : 100%;
    width           : 50vw;
    background-color: var(--node-marketplace-green);
    transition      : height .65s;
    transition      : width .65s;
}

.signup-card-action-button-small {
    width: 140px;
}
