.create-inv-quality-assessment-dialog {
    width: 500px;
}

.p-dialog-content.create-inv-quality-assessment-dialog-content {
    padding: 15px;
}


.create-inv-quality-assessment-dialog-field-container {
    display: flex;
    width: 100%;
    align-items: center;    
}

.create-inv-quality-assessment-dialog-field-label {
    display: inline-block;
    width: 160px;
}
.create-inv-quality-assessment-dialog-field-input {
    width: 300px;
}

.inv-table-menu-option {
    display: inline-block;
    width: 240px;    
}