.contact-vendor-card {
    margin-top: 0px;
    width: 300px;
    border-radius: 10px;
}

.contact-vendor-dialog {
    padding: 0px;    
    border-radius: 10px;
}

.p-dialog .p-dialog-content.contact-vendor-dialog-content {
    padding: 0px;
    border-radius: 10px;
}