
.vendor-image-banner-container-large {
    height: var(--logo-size-large);
}
.vendor-image-banner-container-small {
    height: var(--logo-size-small);
}

.vendor-image-banner {
    max-height: 100%;
    max-width: 100%; 
    height: 100%;   
    object-fit: cover;
}

.vendor-banner-company-name {
    font-family: Verdana, Geneva, Tahoma, sans-serif
}

