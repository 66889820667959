.drag-below-container {
    border-bottom-style: solid;
    border-bottom-color: blue;
    border-bottom-width: 4px;
    /* border-bottom-style: inset;
    border-bottom-color: blue;
    border-bottom-width: 20px; */
}

.drag-above-container {
    border-top-style: solid;
    border-top-color: blue;
    border-top-width: 4px;
}

.section-view-editor {
    width: 100%;
}

.section-view-name-editor {
    width: 100%;
    margin-right: 10px;
}