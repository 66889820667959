.menu-section-panel {
    padding: 0px;
}

.p-dataview-content .p-grid {
    justify-content: space-evenly;
    row-gap        : 10px;
}

.p-card-subtitle {
    color      : brown;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.menu-inv-dataview .p-dataview-header {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid lightgray;
    border-width: 0 0 1px 0;
}