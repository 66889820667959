.confirm-age-dialog {
    width : 50vw;    
}

.confirm-age-dialog-small {
    width : 90vw;    
}

.confirm-age-icon {
    font-size: 2em;
    color    : red;    
}