.section-list-small {
    flex-grow : 1;
    overflow-y  : auto;
    overflow-x: hidden;
    min-height: 0;
    margin-top: calc(10px + var(--logo-size-small) + var(--menu-vendor-search-small));
}

.section-list-large {
    flex-grow : 1;
    overflow-y  : auto;
    overflow-x: hidden;
    min-height: 0;
    margin-top: calc(10px + var(--logo-size-large) + var(--menu-vendor-search-large));
}

.section-list-no-banner-small {
    flex-grow : 1;
    overflow-y  : auto;
    overflow-x: hidden;
    min-height: 0;
    margin-top: calc(10px + var(--logo-size-small) + var(--menu-vendor-search-small));
}

.section-list-no-banner-large {
    flex-grow : 1;
    overflow-y  : auto;
    overflow-x: hidden;
    min-height: 0;
    margin-top: calc(10px + var(--logo-size-large) + var(--menu-vendor-search-large));
}

.menu-vendor-search-container {
    position        : fixed;
    z-index         : var(--fixed-position-container-z-index);
    background-color: white;
}

.prop-65-warning-link {
    padding: 0px;
    margin-left: 5px;
}