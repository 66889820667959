.mobile-list-card-container {
    box-shadow   : -2px 2px 7px 1px lightgray;
    width        : 100vw;
    margin-left  : 2px;
    margin-right : 2px;
    border-radius: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.mobile-list-card-title-bar {
    padding: 1px;
    width  : calc(100vw - 8px);
}

.mobile-list-card-title {
    max-width    : calc(100vw - 55px);
    overflow     : hidden;
    white-space  : nowrap;
    text-overflow: ellipsis;
    font-size    : 16px;
    font-weight  : bold;
}

.mobile-list-card-shadow {
    border-radius: 5px;    
}

.mobile-list-card-split-content-container {
    justify-content: space-between;
    width          : 100%;    
    margin-bottom: 2px;    
    gap: 5px;
}

.mobile-list-card-split-content {
    width          : 45%;    
}

.mobile-list-card-content-label {
    font-size  : 14px;
    font-weight: bold;
}

.mobile-list-card-split-content-value {
    margin-top: 0px;
}

.mobile-list-card-split-content-novalue {
    margin-top: 0px;
    color     : lightgray;
}

.mobile-inv-list .p-dataview-content .p-grid {
    justify-content: space-evenly;
    grid-row-gap: 2px;
    row-gap: 2px;
}











