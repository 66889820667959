.menu-search-bar-input-large {
    width    : 80vw;
    max-width: 450px;
    font-size: x-large;    
}

.menu-search-bar-input-small {
    width    : 100%;
    max-width: 350px;
    font-size: large;
    flex-shrink: 1;
}

.search-bar-and-favorites-container {
    position   : relative;
    padding-top: 2px;
}

.menu-layout-button {
    font-size: x-large;
    background-color: white;
    color: black;    

    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.menu-layout-button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.menu-layout-button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.menu-layout-button:focus {
    box-shadow: 0px 0px 0px 0px;
}

.menu-layout-button-selected {
    background-color: var(--node-marketplace-green);
    color: white;
}