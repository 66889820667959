:root {
    --constraint-treenode-color-swatch-width: 10px;
    --constraint-treenode-color-swatch-height: 40px;
}

.edit-template-field-container {
    display: flex;
    align-items: center;
}    

.edit-template-field-input {
    width: 360px;
}

.edit-template-field-label {
    display: inline-block;
    width: 100px;
}

.general-info-editor {
    gap: 20px;
    padding: 15px;
}

.template-metrics-editor {
    gap: 20px;
    padding: 0px 15px;
}

.template-metric-table-button {
    height: 30px;
}

.template-constraints-editor {
    gap: 20px;
    overflow-y: auto;
    height: calc(100vh - 250px);
}

.template-constraints-list {
    gap: 10px;
}

.template-constraint-list-empty {
    display: flex;
    align-content: center;
    align-items: center;
    height: 60px;
    padding: 10px;
    font-size: larger;
}

.template-constraints-collapse {
    max-width: 1200px;
    border: none;
    
}

.template-constraints-collapse-panel .ant-collapse-header {
    padding: 5px !important;
}

.template-constraints-collapse-panel .ant-collapse-header-text {
    font-size: x-large;
}

.template-constraint-embedded-editor {
    gap: 10px;
    padding: 0px;    
}

.edit-templateconstraint-field-input {
    width: 260px;
    flex-shrink: 1;
}

.edit-templateconstraint-comparison-selector {
    width: 230px;
    flex-shrink: 1;
}

.publish-template-confirm-dialog .ant-modal-content {
    width: 600px;
}

.quality-assessment-test-editor {
    gap: 20px;
    padding: 10px;
}

.quality-assessment-test-entry-list {
    gap: 20px;
    height: calc(100vh - 250px);
    overflow-y: auto;
}

.quality-assessment-test-results {
    gap: 20px;
    align-content: start;
    align-items: start;
    min-width: 200px;
    width: auto;
    padding-left: 30px;    
    border-left: 1px solid lightgray;
}

.quality-assessment-test-eval-button {
    width: 120px;
}

.quality-assessment-test-result-value {
    font-size: 60px;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 120px;
    width: auto;
    padding: 10px;
    min-height: 120px;
    text-align: center;
}

.quality-assessment-test-field {
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
}

.quality-assessment-test-editor-label {
    display: inline-block;
    font-weight: bold;
    min-width: 200px;
    max-width: 200px;
}

.quality-assessment-test-editor-input {
    min-width: 200px;
}

.select-quality-metrics-table-container {
    padding: 10px;
    min-width: 400px;
}

.template-grade-constraint-header {
    padding: 5px;
    gap: 20px;
    align-items: center;
    align-content: center;
    justify-content: start;
    width: 100%;
    height: 24px;
}

.template-grade-constraint-header-title{
    font-size: larger;
}

.template-grade-constraint-header-extra {
    margin-left: auto;
}

.quality-template-constraint-node-tree {
    border: none;
    padding: 0px;
}

.constraint-editor-tree-node {
    padding: 4px !important;
}

.logic-block-editor {
    gap: 10px;
    align-items: center;
}

.constraint-node-icon {
    display: inline-block;
    width: var(--constraint-treenode-color-swatch-width);
    height: var(--constraint-treenode-color-swatch-height);
    background-color: lightgray;
}

.logic-block-icon-and {
    display: inline-block;
    width: var(--constraint-treenode-color-swatch-width);
    height: var(--constraint-treenode-color-swatch-height);
    background-color: rgb(117, 206, 117);
}

.logic-block-icon-or {
    display: inline-block;
    width: var(--constraint-treenode-color-swatch-width);
    height: var(--constraint-treenode-color-swatch-height);
    background-color: rgb(70, 127, 234);
}