:root {
    --side-menu-width                 : 200px;
    --app-top-bar-height              : 60px;
    --setup-workflow-steps-height     : 60px;
    --setup-workflow-commandbar-height: 60px;
    --node-marketplace-green          : #34ce9e;

    --small-device-max-width: 1023;
    --large-device-min-width: 1024;

    --progress-spinner-size-small: 20px;
    --logo-size-small            : 60px;    
    --menu-vendor-search-small   : 60px;
    --global-search-small        : 60px;

    --progress-spinner-size-large: 20px;
    --logo-size-large            : 120px;    
    --menu-vendor-search-large   : 65px;
    --global-search-large        : 70px;

    --fixed-position-container-z-index: 20;

    flex-direction: column;
    display       : flex;
}

.p-button {
    color        : #ffffff;
    background   : var(--node-marketplace-green);
    border       : 1px solid var(--node-marketplace-green);
    padding      : 0.5rem 1rem;
    font-size    : 1rem;
    transition   : background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

.p-button:not(a):not(.p-disabled):hover {
    background  : #31b48b;
    color       : #ffffff;
    border-color: #31b48b;
}

.p-button:not(a):not(.p-disabled):active {
    background  : #31b48b;
    color       : #ffffff;
    border-color: #31b48b;
}

.p-button.p-button-outlined {
    background-color: transparent;
    color           : var(--node-marketplace-green);
    border          : 1px solid;
}

.main-content-panel {
    flex-direction  : column;
    display         : flex;
    background-color: #FDFDFD;
}

.full-height-content-panel {
    flex-direction  : column;
    display         : flex;
    background-color: #FDFDFD;
    
    height: calc(100vh - var(--app-top-bar-height));
}

.table-icon-circle-check {
    font-size: 30px !important;
    color    : var(--node-marketplace-green);
}