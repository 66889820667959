.not-authorized-large-device-button {
    width      : 250px;
    height     : 50px;
    font-size  : 24px;
    font-weight: bold;
}

.not-authorized-small-device-button {
    width      : 160px;
    height     : 50px;
    font-size  : 14px;
    font-weight: bold;
}

.not-authorized-small-device-message {
    height: 100px;
    text-align: center;
    font-size: x-large;
}

.not-authorized-large-device-message {
    height: 100px;
    text-align: center;
    font-size: xx-large;
}