.default-wait-display-spinner {
    font-size: 2em;
    color    : #009b99;
}

.large-wait-display-spinner {
    font-size: 3em;
    color    : #009b99;
}

.custom-progress-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 2s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {

    100%,
    0% {
        stroke: var(--node-marketplace-green);
    }
};