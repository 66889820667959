.node-menu-card.p-card .p-card-content {
    padding: 0rem 0rem 1rem 0rem;
}
.node-inv-card-title {
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 20px;
}

.node-inv-card-sub-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    /* font-size: 14px; */
    padding-right: 10px;
    height: 24px;
}

.node-inv-card-sub-title-left {
    width: 100%;
}

.node-inv-card-sub-title-right {
    width: 50%;
}

.node-menu-card {
    width: 100%;
    max-width: 400px;
    height: 550px;
    border-radius: 10px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    box-shadow: -2px 2px 5px 1px lightgray;
}

.node-card-content-shadow {
    border-radius: 10px;
    box-shadow: -1px 1px 15px 1px #EEEEEE;
}

.node-inv-card-split-content-container {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    min-height: 70px;
}

.node-inv-card-split-content {
    align-items: flex-start;
    justify-content: center;
    /* overflow: hidden; */
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 48%;
    padding-top: 10px;
    min-height: 70px;
}

.node-inv-card-split-content-label {
    font-size: 16px;
    font-weight: bold;
}

.node-inv-card-split-content-value {
    font-size: 14px;
    margin-top: 8px;
}

.node-inv-card-split-content-novalue {    
    margin-top: 8px;
    color: lightgray;
    font-size: 12px;
}

.node-inv-card-description-container {
    position: relative;
    margin-top: 2px;
    width: 100%;
    height: 74px;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: start;
    padding-right: 1rem;
    
}


.node-inv-card-package-label {
    font-size: small;
    color: grey;
}

.node-inv-card-command-bar:last-child {
    margin-left: auto;
}