.edit-product-container {
    width: 60vw;    
    max-height: 90vh;
    margin-top: 10px;
    /* max-width: 800px;
    max-height: 600px; */
}

.edit-product-notes-input {
   border-radius: 5px;   
   min-height: 100px;
}