:root {
    --live-menu-tab-color: rgb(78, 74, 74);
}

.node-menu-tab .p-tabview-title {
    color: var(--live-menu-tab-color);
}

.node-menu-tab .p-tabview-selected .p-tabview-nav-link {
    background  : #ffffff !important;
    border-color: var(--live-menu-tab-color) !important;
    color       : var(--live-menu-tab-color) !important;
}

.node-menu-tab .p-tabview-nav-link:hover {
    background  : #ffffff;
    border-color: gray  !important;
    color       : gray  !important;
}

.node-menu-tab.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus  {
    border-color: var(--live-menu-tab-color);
    outline       : 0 none;
    outline-offset: 0;
    box-shadow    : inset 0 0 0 0.0rem var(--live-menu-tab-color);
}