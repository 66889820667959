.node-list-card-header-column {    
    border: 0px solid black;
}

.node-inv-list-card-title {
    width       : 240px;        
    font-size    : 16px;
}

.node-list-card-content-novalue {    
    color     : lightgray;
}

.node-list-card-description-container {
    margin-top     : 0px;
    width          : 100%;
    height         : 60px;
    overflow       : clip;
    justify-content: start;
}

.node-list-card-package-label {
    font-size      : small;
    color          : grey;
}

.node-list-card-command-bar:last-child {
    margin-left: auto;
}