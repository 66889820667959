.inventory-list-card {
    height: calc(100vh - 260px);    
    margin-bottom: 0px !important;
}

.inventory-list {    
    overflow  : auto;
}

.inventory-direct-menu-assignment-column {
    width: 120px;
}

.inventory-menu-assignment-indicator {
    font-size: 30px !important;
    color    : var(--node-marketplace-green);
}