.notes-list-container {
    width: 60vw;
    max-width: 1000px;
    max-height: 90vh;
    min-height: 50vh;
    margin-top: 10px;
    gap: 5px;
}

.notes-list {
    gap: 10px;
    max-height: calc(60vh - 200px);
    overflow-y: auto;
}

.notes-editor {
    width: 100%;
    border-radius: 5px;
}