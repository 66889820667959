.live-search-field-results {
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
}

.live-search-field-readonly-display {
    border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.live-search-field-clear-value {
    background-color: transparent;
    color: lightgray;
    border-color: lightgray;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}