.company-editor {
    width: 400px;
    padding-top: 20px;
    gap: 20px;
}
.company-field-input {
    width: 100%;
    min-width: 176px;
}
.company-field-container {
    display: flex;
    flex-direction: row;
    width: 100%;        
    align-items: center;
}
.company-field-label {
    flex: 0 0 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100px;    
}