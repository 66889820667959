.category-list-container {
    max-width: 600px;
    /* min-width: 400px; */
    width: calc(40vw - var(--side-menu-width));
}

.category-list-card {
    flex-direction: column;
    display       : flex;
}

.category-list {    
    height: calc(100vh - 220px);    
    overflow: auto;
}