.side-menu-scroll .p-scrollpanel-wrapper {
    border-right: 10px solid var(--surface-b);
}

.side-menu-scroll .p-scrollpanel-bar {
    background-color: var(--surface-300);
    opacity         : 1;
    transition      : background-color .2s;
}

.side-menu-scroll .p-scrollpanel-bar:hover {
    background-color: var(--surface-400);
}

/* .p-panelmenu {
    margin                 : 0;
    padding                : 1px;
    color                  : #070808;
    background-color       : var(--node-marketplace-green);
    font-weight            : bolder;
    border-top-right-radius: 0;
    border-top-left-radius : 0;
} */

/* .p-panelmenu .p-panelmenu-header {
    margin                 : 0;
    padding                : 1px;
    color                  : #070808;
    background-color       : var(--node-marketplace-green);
    font-weight            : bolder;
    border-top-right-radius: 0;
    border-top-left-radius : 0;
} */

.p-panelmenu .p-panelmenu-header>a {
    padding      : 1rem;
    border       : 1px solid #dee2e6;
    color        : #495057;
    background   : #f8f9fa;
    font-weight  : 600;
    border-radius: 3px;
    transition   : box-shadow 0.2s;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
    background  : #e9ecef;
    border-color: #dee2e6;
    color       : #495057;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight>a {
    background                : #cdd2d8;
    border-color              : var(--node-marketplace-green);
    color                     : #2c2f33;
    border-bottom-right-radius: 8px 8px;
    border-bottom-left-radius : 8px 8px;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
    border-color: #dee2e6;
    background  : #e9ecef;
    color       : #495057;
}

.p-selected-route {
    border-right              : 6px solid var(--node-marketplace-green);
}

/* .p-menu {
    padding      : 0.25rem 0;
    background   : #ffffff;
    color        : #495057;
    border       : 1px solid #dee2e6;
    border-radius: 3px;
    width        : 12.5rem;
}

.p-menu .p-menuitem-link {
    padding      : 0.75rem 1rem;
    color        : #495057;
    border-radius: 0;
    transition   : box-shadow 0.2s;
    user-select  : none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
    color: #495057;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    color       : #6c757d;
    margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
    color: #6c757d;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #495057;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #6c757d;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #6c757d;
}

.p-menu .p-menuitem-link:focus {
    outline       : 0 none;
    outline-offset: 0;
    box-shadow    : inset 0 0 0 0.15rem #a6d5fa;
}

.p-menu.p-menu-overlay {
    background: #ffffff;
    border    : 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}



.p-menu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin    : 0.25rem 0;
} */