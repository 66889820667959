.product-details-container {
    width : 100vw;       
    margin-top: calc(var(--logo-size-large));
}

.product-details-container-small {
    width : 100vw;   
    margin-top: calc(var(--logo-size-small));    
}

.product-page-top-panel-container {
    position: fixed;
    z-index: var(--fixed-position-container-z-index);
    background-color: white;
}

.product-details-container .node-menu-card {
    width: 100%;
    max-width: 500px;
    height: 720px;
    border-radius: 10px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    box-shadow: -2px 2px 5px 1px lightgray;
}

.product-details-container-small .node-menu-card {    
    width: 95vw;
    max-width: 500px;
    height: 720px;
    border-radius: 10px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    box-shadow: -2px 2px 5px 1px lightgray;
}

.product-details-container .node-inv-card-description-container {
    margin-top: 10px;
    width: 100%;
    height: 180px;
    overflow: auto;    
    justify-content: start;
}

.product-details-container-small .node-inv-card-description-container {
    margin-top: 10px;
    width: 100%;
    height: 180px;
    overflow: auto;    
    justify-content: start;
}