.category-view-grab-icon {
    width: 40px;
}

.category-view-editor {
    width: 100%;   
}

.category-view-name-editor {
    width: 80%;
    margin-right: 10px;
}