.success-icon {    
    color: green;
    font-size: x-large;    
}

.fail-icon {    
    color: red;
    font-size: x-large;
}

.queued-icon {    
    color: var(--blue-500);
    font-size: x-large;
}

.in-progress-icon {    
    color: var(--blue-500);
    font-size: x-large;
}