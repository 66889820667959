.search-input {
    width: 400px;    
    margin-right: 5px;
}

.search-input-small {
    width: 50vw;    
    margin-right: 5px;
}

.search-bar-small {
    background-color: grey;
    padding: 10px;
    position: fixed;
    width: 100vw;
    z-index: var(--fixed-position-container-z-index);
    height: var(--global-search-small);
}

.search-bar {
    background-color: grey;
    padding: 15px;
    position: fixed;
    width: 100vw;
    z-index: var(--fixed-position-container-z-index);
    height: var(--global-search-large);
}