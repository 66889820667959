@font-face {
    font-family: 'Abel-Regular';
    src        : local('Abel-Regular'), url(./Abel-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'AbrilFatface-Regular';
    src        : local('AbrilFatface-Regular'), url(./AbrilFatface-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'DancingScript-Regular';
    src        : local('DancingScript-Regular'), url(./DancingScript-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'DelaGothicOne-Regular';
    src        : local('DelaGothicOne-Regular'), url(./DelaGothicOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'FredokaOne-Regular';
    src        : local('FredokaOne-Regular'), url(./FredokaOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'GravitasOne-Regular';
    src        : local('GravitasOne-Regular'), url(./GravitasOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'JosefinSans-Regular';
    src        : local('JosefinSans-Regular'), url(./JosefinSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-Regular';
    src        : local('Montserrat-Regular'), url(./Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Mulish-Regular';
    src        : local('Mulish-Regular'), url(./Mulish-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src        : local('Poppins-Regular'), url(./Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway-Regular';
    src        : local('Raleway-Regular'), url(./Raleway-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Regular';
    src        : local('Roboto-Regular'), url(./Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Sanchez-Regular';
    src        : local('Sanchez-Regular'), url(./Sanchez-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'TradeWinds-Regular';
    src        : local('TradeWinds-Regular'), url(./TradeWinds-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Ubuntu-Regular';
    src        : local('Ubuntu-Regular'), url(./Ubuntu-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Ultra-Regular';
    src        : local('Ultra-Regular'), url(./Ultra-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'WorkSans-Regular';
    src        : local('WorkSans-Regular'), url(./WorkSans-Regular.ttf) format('truetype');
}