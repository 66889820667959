.quality-assessment-editor {
    gap: 20px;
}

.quality-assessment-editor-cmd-bar {
    gap: 20px;
    align-items: center;

}

.quality-assessment-editor-entry-list {
    gap: 10px;
    max-height: calc(100vh - 400px);
    overflow-x: auto;
    overflow-y: auto;

}

.quality-assessment-editor-field {
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
    align-items: center;
}

.quality-assessment-editor-label {
    display: inline-block;
    font-weight: bold;
    min-width: 240px;
    max-width: 240px;
}

.quality-assessment-editor-input {
    width: 100%;
}

.quality-assessment-editor-audit {
    font-size: smaller;
    min-width: 250px;
}

.quality-assessment-modal-dialog-cancel {
    display: none;
}

.quality-assessment-modal-dialog {
    
    max-width: 1200px;
}

.p-dialog-content.quality-assessment-modal-dialog-content {
    padding: 20px;        
}