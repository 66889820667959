.banner-container {
    height: 300px;
    width: 100%;
    /* background-color: lightgrey; */
}

.search-label-container {
    height: 80px;
    width: 100%;
    /* background-color: whitesmoke; */
}

.search-container {
    height: 120px;
    width: 100%;
    /* background-color: whitesmoke; */
}

.text-search-label {
    font-size: xx-large;
    text-align: center;    
}

.text-search-label-small {
    font-size: x-large;    
    text-align: center;    
}

.text-search-small {
    font-size: small;
    max-width: 800px;
    width: 85vw;
}

.text-search-large {
    font-size: xx-large;
    max-width: 800px;
    width: 85vw;
}
