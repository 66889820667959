#top-fixed-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: darkorange;
    height: 100px;
    width: 100vw;    
    z-index: 20;
    font-size: xx-large;
    text-align: center;
    margin-top: var(--app-top-bar-height);
}

#scroll-test-root {
    display: flex;
    flex-direction: column;
    background-color:darkmagenta;
    
}

.item-template {
    margin-left: 30px;
    background-color: dodgerblue;
    padding: 5px;
}

#scroll-content-container {
    margin-top: 100px;
}