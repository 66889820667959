.category-assignment-container {
    width : 600px;    
}
.category-assignment-tree-table {
    width : 100%;
    height: 70vh;
}

.menu-tree-table-row {
    background-color: var(--node-marketplace-green) !important;    
    font-weight     : bold;    
}

.category-tree-table-row {
    background-color: white;    
}

.category-assignment-tree-table.p-treetable .p-treetable-tbody > tr > td {    
    padding: 5px 0px 5px 0px;
}