.accept-terms-checkbox .p-checkbox {
    width       : 30px;
    height      : 30px;
    margin-top  : 24px;
    margin-right: 10px;
}

.accept-terms-checkbox .p-checkbox .p-checkbox-box {
    width : 30px;
    height: 30px;
}

.accept-terms-checkbox .p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 16px;
}

.accept-terms-checkbox .p-checkbox-label {
    font-size: 16px;
}

.terms-viewer-large-device {
    height    : calc(60vh - var(--setup-workflow-steps-height) - var(--setup-workflow-commandbar-height) - var(--setup-workflow-commandbar-height));
    max-height: calc(60vh - var(--setup-workflow-steps-height) - var(--setup-workflow-commandbar-height) - var(--setup-workflow-commandbar-height));
    overflow-y: scroll;
}

.terms-viewer-small-device {
    height: calc(100vh - var(--setup-workflow-steps-height) - (var(--setup-workflow-commandbar-height) * 3));
    max-height: calc(100vh - var(--setup-workflow-steps-height) - (var(--setup-workflow-commandbar-height) * 3));
    overflow-y: scroll;
}