.favorites-list-vendor-name {
    font-size: small;    
}

.favorites-list-menu-name {
    font-size: large;
    font-weight: bold;
}

.favorites-dataview-container {
    height: calc(100vh - var(--app-top-bar-height) - 90px);
    overflow-y: auto;
}