.quality-template-dashboard {
    gap: 10px;
}

.add-template-field-container {
    display: flex;
    align-items: center;
}

.add-template-field-input {
    width: 360px;
}
.add-template-field-label {
    display: inline-block;
    width: 100px;
}

.add-quality-template-dialog {
    gap: 20px;
    padding: 20px
}