.edit-marketing-details-container {
    width: 60vw;
    max-width: 1000px;
    /* max-height: 80vh; */
    min-height: 50vh;
    margin-top: 10px;
}

.metrc-data-label {
    width: 60px;
}

.marketing-details-description-textarea {
    max-height: 200px;
    /* text-overflow: clip;
    white-space: nowrap;
    overflow-y: auto; */
    min-width: 100%;
    max-width: 100%;
}
