body {
    margin          : 0px;
    padding         : 0px;
    background-color: var(--surface-0);    
    overflow-x      : hidden;    
    /* overflow-y      : hidden; */
}

.app-top-bar {
    height          : var(--app-top-bar-height);
    width           : 100%;
    margin-bottom   : 2px;
    background-color: var(--surface-50);
    position        : fixed;
    top             : 0;
    z-index         : var(--fixed-position-container-z-index);
}

.docked-menu-container {
    position           : fixed;
    width              : var(--side-menu-width);
    height             : 100%;
    overflow-y         : auto;
    /* background-color: var(--surface-500); */
}

.app-content-container {
    margin-top: var(--app-top-bar-height);
    flex-direction: column !important;
    display: flex !important;
}

.overlay-menu-container {
    padding: 0px 0px 0px 0px;
    width  : var(--side-menu-width);
}

.app-top-bar-logo-small {
    margin-left: 10px;
}

.app-content-panel {
    margin-left   : 0px;
    padding       : 0px;
    height        : calc(100vh - var(--app-top-bar-height));
    display: flex;
    flex-direction: column;
}

.app-content-panel-with-side-menu {
    margin-left: var(--side-menu-width);
    padding    : 0px;
    height     : calc(100vh - var(--app-top-bar-height));
    display: flex;
    flex-direction: column;
}

.login-error-dialog-reject {
    display: none
}

.pi-exclamation-circle {
    color: crimson;
}

.alerts-bar {    
    margin-right: 5px;
}

.p-dialog .p-dialog-header {
    border-bottom : solid 1px lightgray;
    padding-top   : 8px;
    padding-bottom: 8px;

}

.content-panel-wait-display {
    width : 100%;
    height: 75vh;
}