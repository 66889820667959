.hidden-print-container {
    display: none;
}

@media print {
    .hidden-print-container {
        display: block;
    }
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {

    html,
    body {
        height                    : initial !important;
        overflow                  : initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top       : 1rem;
        display          : block;
        page-break-before: always;
    }
}

@media print {
    .print-on-same-page {
        page-break-inside: avoid;
    }
}

@page {
    size  : auto;
    margin: 20mm;
}