.product-page-image-gallery {
    max-width : 60vw;
}

.product-page-image-gallery-small {
    width: 100vw;    

}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
    color: white;
}

.p-galleria .p-galleria-item-nav {
    background: rgba(125, 125, 125, .25) !important;
    
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    margin: 0 0.5rem;    
}


.live-menu-gallery-video {
    width: 80vw;
    height: 60vh;
}
