.flip-card {
    perspective: 800px;
    /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position       : relative;
    width          : 100%;
    height         : 100%;
    transition     : transform 0.8s;
    transform-style: preserve-3d;
}

/* Assign the back-of-card-selected class to the element that has the flip-card-inner class to start the flip  */
.back-of-card-selected.flip-card-inner {
    transform: rotateY(180deg);
}

/* Hide the front card and the back card when rotated */
.flip-card-front,
.flip-card-back {
    position                   : absolute;
    width                      : 100%;
    height                     : 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility        : hidden;
}

.flip-card-front {
    transform       : rotateY(0deg);
}

.flip-card-back {    
    transform: rotateY(180deg);
}