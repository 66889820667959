.vendor-title {
    font-size: x-large;
}
.menu-title {
    font-size: x-large;
    border-bottom: 1px solid lightgray;
}
.marketingview-info-row {
    align-items: center;    
    gap: 50px;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
}
.marketing-view-info-panel {
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
}

.marketing-view-info-panel-title {
    font-size: large;
    font-weight: 700;
    min-width: 120px;
    border-bottom: 1px solid lightgray;
    
}

.marketing-view-info-panel-value {
    font-size: large;
}

.menu-list-container {
    gap: 10px;
}

.menu-list-item-row {
    font-size: x-large;
}

.menu-list-item-link {
    font-size: x-large;
}