.search-results-container {
    padding: 5px 10px;    
    flex-grow : 1;
    overflow  : auto;
    margin-top: var(--global-search-large);
}

.search-results-container-small {
    padding: 5px;
    flex-grow : 1;
    overflow  : auto;
    margin-top: var(--global-search-small);
}

.inventory-list-view-title {
    font-size: larger;
    font-weight: bold;
}

.inventory-list-view-vendor {
    font-size: small;
    font-weight: lighter;
    border-top: 1px solid darkgray;
}