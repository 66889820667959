.pi-exclamation-circle .diagnostic {
    color:crimson;
    font-size: xx-large;
    font-weight: bolder;
}

.pi-check .diagnostic {
    color:rgb(20, 220, 70);
    font-size: xx-large;
    font-weight: bolder;
}

.inline-result {
    display: inline;
}

.inline-result-icon {
    display: inline;
    float: right;
}