.custom-table {
    table-layout: fixed;
    width: 1px !important;
    min-width: 1px !important;
}

.custom-table-column {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    min-width: 1px !important;
}

.p-datatable .custom-table .p-datatable-thead > tr > th {    
    position: sticky;
    top: 0;
    z-index: 1;    
    border-right: 1px dashed rgb(237, 235, 235);
    white-space: normal;
    /* text-overflow: ellipsis;
    overflow-x: hidden; */
  }